import { useCommon } from 'contexts/common';
import useImpressionTracking, {
  useImpressionTrackingProps,
} from 'hooks/gtm/use-impression-tracking';
import { Children, cloneElement, isValidElement, useRef } from 'react';
import { gtm, viewElement } from 'tracking';

interface ProductImpressionsTrackerProps {
  collectionHandle?: string;
  /** The name of the component being tracked, e.g. "Collection Selector" */
  element: string;
  trackingGroupName: string;
  options?: useImpressionTrackingProps['options'];
  product: {
    id: string | number;
    promoteVariantId?: string | number;
    title: string;
    productType: string;
    tags: string | Array<string>;
  };
  /** The name of collection */
  list: string;
  /** Position in collection */
  position: number;
}

/**
 * Set up product impressions, interacting with setProductImpressionsQueue via common provider
 */
const ProductImpressionsTracker: React.FC<
  React.PropsWithChildren<ProductImpressionsTrackerProps>
> = ({
  children,
  collectionHandle,
  element,
  list,
  options = { threshold: 0.5 },
  position,
  product,
  trackingGroupName,
}) => {
  const { setProductImpressionsQueue: setQueue } = useCommon();
  const ref = useRef(null);
  useImpressionTracking({
    impressionStoreKey: trackingGroupName,
    impressionId: `${product.id}-${product.promoteVariantId || 0}`,
    trackingFn: (isInViewport) => {
      if (isInViewport) {
        setQueue((prev) => [
          ...prev,
          {
            productId: product.id,
            productType: product.productType,
            productTitle: product.title,
            tags: Array.isArray(product.tags)
              ? product.tags
              : product.tags.split(','),
            list,
            position,
          },
        ]);
        if (position + 1 === 3 || position + 1 === 9) {
          gtm(
            viewElement({
              element: collectionHandle
                ? [element, `${collectionHandle}`, `n=${position + 1}`]
                : [element, `n=${position + 1}`],
              list,
            })
          );
        }
      }
    },
    ref,
    options,
    debounce: 100,
  });

  const child = Children.only(children);

  if (isValidElement<any>(child)) {
    return cloneElement(child, { ref });
  }

  return <>{children}</>;
};

export default ProductImpressionsTracker;
