import { PetType } from 'api/questionnaire';
import {
  isCatSegmentation,
  SEGMENTS_SLUGIFIED_FILTER_MAP,
} from 'constants/segmentation';
import { FilterConfig } from 'interfaces/collection-filter';
import { ALL_AVAILABLE_FILTERS } from 'modules/collection/state/filter-config';

type Input = {
  petType: PetType;
  excludeKeys?: string[];
};

/**
 * Get all available filters based on pet type
 */
const getAllFilter = ({ petType, excludeKeys = [] }: Input): FilterConfig => {
  const allFilter = JSON.parse(
    JSON.stringify(ALL_AVAILABLE_FILTERS)
  ) as FilterConfig;

  const filter = Object.entries(SEGMENTS_SLUGIFIED_FILTER_MAP)
    .filter((entry) => {
      if (petType === 'cat') {
        if (!Array.isArray(entry[1])) {
          return isCatSegmentation(entry[1]);
        }
      } else if (petType === 'dog') {
        if (Array.isArray(entry[1])) {
          return !!entry[1].find((v) => !isCatSegmentation(v));
        }
        return !isCatSegmentation(entry[1]);
      }
    })
    .map((v) => v[0]);

  const filteredAllFilter = Object.keys(allFilter)
    .filter((key) => !excludeKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = allFilter[key];
      return obj;
    }, {} as FilterConfig);

  if (!excludeKeys.includes('nutritional-needs')) {
    filteredAllFilter['nutritional-needs'] = {
      tags:
        allFilter['nutritional-needs']?.tags.filter(
          (v) => filter.indexOf(v) > -1
        ) || [],
      compare: allFilter['nutritional-needs']?.compare,
    };
  }

  return filteredAllFilter;
};

export default getAllFilter;
