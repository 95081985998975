import {
  isAgeSegmentation,
  isBreedSegmentation,
  isNutritionSegmentation,
  SegmentationData,
  SegmentationIds,
} from 'constants/segmentation';
import { InputProductType } from 'interfaces/api-input/input-product-type';
import { CollectionFilter, FilterConfig } from 'interfaces/collection-filter';
import { CatGeneralCdpHandle } from 'models/collection/constants/cat-general-cdp';
import { DogGeneralCdpHandle } from 'models/collection/constants/dog-general-cdp';
import FILTER_CONFIG from 'modules/collection/state/filter-config';
import { CDP_MAPPING } from 'utils/api/smartCollection';

/** Handle of collection  */
type Handle = string;

export const CdpType = {
  BreedCdp: '0',
  NeedCdp: '1',
  AgeTypeCdp: '2',
  FoodTypeCdp: '3',
  Home: '4',
  YourPetProfile: '5',
} as const;

type CdpType = (typeof CdpType)[keyof typeof CdpType];

type SpecialHandle = typeof CdpType.YourPetProfile | typeof CdpType.Home;

/**
 * Create a map which is mapped with CDP type
 * key : handle / special cdp type (home / your pet profile)
 * Value : CDP type
 */
const CdpTypeMapper = new Map<Handle, CdpType>();

/**
 * Check if the collection is snack collection
 */
const isSnackCdp = (handle: string): boolean =>
  CDP_MAPPING.filter(
    (config) => config.productType === InputProductType.Snacks
  ).some((config) =>
    config.url.some((url) => url.replace(/^\/collections\//, '') === handle)
  );

const getKeyBySegmentationId = ({
  segmentation,
}: {
  segmentation: SegmentationIds;
}): CdpType | undefined => {
  if (isBreedSegmentation(segmentation)) {
    return CdpType.BreedCdp;
  } else if (isAgeSegmentation(segmentation)) {
    return CdpType.AgeTypeCdp;
  } else if (isNutritionSegmentation(segmentation)) {
    return CdpType.NeedCdp;
  }
};

/** set segmentation related cdp */
SegmentationData.segmentation.map((segment) => {
  const cdpTypeKey = getKeyBySegmentationId({ segmentation: segment.id });
  if (cdpTypeKey) {
    segment.redirectPages.forEach((url) => {
      const handle = url.replace(/^\/collections\//, '');
      CdpTypeMapper.set(handle, cdpTypeKey);
    });
  }
});

/** set general cdp */
CDP_MAPPING.map((v) => v.url).forEach((urls) => {
  urls.forEach((url) => {
    const handle = url.replace(/^\/collections\//, '');
    CdpTypeMapper.set(handle, CdpType.FoodTypeCdp);
  });
});

const SPECIAL_HANDLE = [CdpType.Home, CdpType.YourPetProfile];

SPECIAL_HANDLE.forEach((cdpType) => {
  CdpTypeMapper.set(cdpType, cdpType);
});

/**
 * Mapper for CDP type and filter combination and it's order.
 * @see https://docs.google.com/spreadsheets/d/1cE3zs5OLUE1jgvy1AXcALUTJjzJblWJ72IW3MGuiC6s/edit?pli=1&gid=1714492367#gid=1714492367
 */
export const FilterOrderMapper: Record<
  CdpType,
  Array<CollectionFilter> | Array<Array<CollectionFilter>>
> = {
  [CdpType.BreedCdp]: ['feed-type', 'sorts', 'desires'],
  [CdpType.NeedCdp]: ['feed-type', 'sorts', 'desires'],
  [CdpType.AgeTypeCdp]: ['feed-type', 'sorts', 'desires'],
  [CdpType.FoodTypeCdp]: [
    // General
    ['nutritional-needs', 'age', 'sorts', 'desires', 'sizes'],
    // When pet profile is active
    ['sorts', 'desires', 'sizes'],
  ],
  [CdpType.Home]: ['nutritional-needs', 'age', 'feed-type', 'sorts'],
  [CdpType.YourPetProfile]: ['sorts', 'desires'],
} as const;

export type FilterOrderMapper =
  (typeof FilterOrderMapper)[keyof typeof FilterOrderMapper];

const getFilterOrder = ({
  isPetProfileApplied,
  handle,
}: {
  isPetProfileApplied?: boolean;
  handle: string;
}) => {
  const cdpType = CdpTypeMapper.get(handle);

  if (!cdpType) {
    return undefined;
  }

  let filters = FilterOrderMapper[cdpType];

  // Add snack-type filter for snack collections
  if (isSnackCdp(handle)) {
    if (Array.isArray(filters[0])) {
      filters = filters.map((filterSet) => ['snack-type', ...filterSet]);
    } else {
      filters = ['snack-type', ...(filters as Array<CollectionFilter>)];
    }
  }

  if (
    filters.some((v) => Array.isArray(v)) &&
    typeof isPetProfileApplied === 'boolean'
  ) {
    const index = isPetProfileApplied ? 1 : 0;
    return (filters as Array<Array<CollectionFilter>>)[index];
  }

  return filters as Array<CollectionFilter>;
};

function reorderObject(obj, keysOrder) {
  const reorderedObj = {};

  // Add properties to reorderedObj based on keysOrder
  keysOrder.forEach((key) => {
    if (key in obj) {
      reorderedObj[key] = obj[key];
    }
  });

  return reorderedObj;
}

/**
 * Modify config order by definitions
 */
export const modifyConfig = ({
  filterConfig,
  handle,
  isPetProfileApplied,
}: {
  filterConfig: FilterConfig;
  handle: SpecialHandle | string;
  isPetProfileApplied?: boolean;
}): FilterConfig => {
  const reorderConfig = getFilterOrder({
    handle,
    isPetProfileApplied,
  });

  if (!reorderConfig) {
    return filterConfig;
  }

  const temp = { ...filterConfig };

  // Required for marketing usage
  if (handle === CatGeneralCdpHandle.CatWetFoodHandle) {
    const catWetFoodFilters = FILTER_CONFIG['fur-katzen-nassfutter'];
    reorderConfig.splice(1, 0, 'line');
    temp.line = catWetFoodFilters?.line;
  }

  if (handle === DogGeneralCdpHandle.DogDryFoodHandle) {
    const dogDryFoodFilters = FILTER_CONFIG['fur-hunde-trockenfutter'];
    reorderConfig.splice(0, 0, 'line');
    temp.line = dogDryFoodFilters?.line;
  }

  return reorderObject(temp, reorderConfig);
};
