import { SmartCollectionInput } from 'api/product/getSmartColleciton/types';
import {
  SegmentationIds,
  getSegmentationFromUrl,
  isAgeSegmentation,
  isAllergySegmentation,
  isBreedSegmentation,
  isCatSegmentation,
  isNutritionSegmentation,
} from 'constants/segmentation';
import { PetContextValues } from 'contexts/pet';
import { PetProfile } from 'contexts/pet-profile';
import { SegmentationStore } from 'contexts/segmentation';
import { InputProductType } from 'interfaces/api-input/input-product-type';
import { omitNullableHandler } from 'utils/type-helper';

type CdpConfig = {
  url: Array<string>;
  productType: InputProductType;
};

export const CDP_MAPPING: Array<CdpConfig> = [
  {
    url: ['/collections/fur-hunde-nassfutter'],
    productType: InputProductType.WetFood,
  },
  {
    url: ['/collections/fur-hunde-trockenfutter'],
    productType: InputProductType.DryFood,
  },
  {
    url: ['/collections/fur-hunde-barf-frische-menus'],
    productType: InputProductType.Barf,
  },
  {
    url: ['/collections/fur-hunde-snacks'],
    productType: InputProductType.Snacks,
  },
  {
    url: ['/collections/fur-katzen-nassfutter'],
    productType: InputProductType.WetFood,
  },
  {
    url: ['/collections/fur-katzen-trockenfutter'],
    productType: InputProductType.DryFood,
  },
  {
    url: ['/collections/fur-katzen-barf-frische-menus'],
    productType: InputProductType.Barf,
  },
  {
    url: ['/collections/fur-katzen-snacks'],
    productType: InputProductType.Snacks,
  },
];

export const getProductTypeFromCDPUrl = ({ url }: { url: string }) => {
  const trimmedUrl = url.replace(/\/collections\/[^\/]+\/.*$/, '');

  return CDP_MAPPING.find(
    // It needs to remove the filters in the url, in case these are present
    (seg) => seg.url.some((redirectPage) => redirectPage === trimmedUrl)
  );
};

/**
 * Check if cdp is a generic cdp (e.g wet food, dry food )
 */
export const isGenericCdpPage = ({ url }: { url: string }): boolean => {
  return !!getProductTypeFromCDPUrl({ url });
};

const processSegmentation = (
  seg: SegmentationIds
): Partial<SmartCollectionInput> => ({
  ...(isBreedSegmentation(seg) && { breed: seg }),
  ...(isAgeSegmentation(seg) && { age: seg }),
  ...(isNutritionSegmentation(seg) && { needs: [seg] }),
  ...(isAllergySegmentation(seg) && { allergies: [seg] }),
});

/**
 * Generate api input from petProfile type
 */
export const generateInputFromPetProfile = ({
  petProfile,
  omitFoodType = false,
}: {
  petProfile: PetProfile;
  omitFoodType?: boolean;
}): SmartCollectionInput => {
  const input = generateInputFromSegmentation({
    segmentation: {
      breedSegmentation: petProfile.selectedBreed,
      needSegmentation: petProfile.selectedNeeds,
      ageSegmentation: petProfile.selectedAge,
      allergySegmentation: petProfile.selectedAllergies,
    },
  });

  return {
    ...input,
    ...(omitFoodType ? {} : { food_types: petProfile.selectedFoodTypes }),
  };
};

const keysToOmit: (keyof SegmentationStore)[] = ['lastSegmentation'];

/**
 * Generate api input from segmentation (single or multiple)
 */
export const generateInputFromSegmentation = ({
  segmentation,
}: {
  segmentation: SegmentationIds | SegmentationStore;
}): SmartCollectionInput => {
  if (typeof segmentation === 'string') {
    return processSegmentation(segmentation) as SmartCollectionInput;
  }

  const values = Object.entries(segmentation)
    .map(([key, value]) => {
      if (!keysToOmit.includes(key as keyof SegmentationStore)) {
        return value;
      }
    })
    .flat()
    .filter(omitNullableHandler)
    .sort();
  let apiInput: Partial<SmartCollectionInput> = {};

  for (const value of values) {
    const processed = processSegmentation(value);
    apiInput = mergeDynamicInput({
      target: apiInput,
      source: processed,
    });
  }

  return apiInput as SmartCollectionInput;
};

/**
 * Remove unrelated segmentation ids from store based on pet type
 */
export const retrieveSegmentationByPetType = ({
  segmentation,
  currentPetType,
}: {
  segmentation: SegmentationStore;
  currentPetType: PetContextValues;
}) => {
  if (!currentPetType) {
    return segmentation;
  }
  const output: SegmentationStore = {};

  Object.entries(segmentation).forEach((entry) => {
    let value;
    if (Array.isArray(entry[1])) {
      value = entry[1].filter((v) => {
        if (isCatSegmentation(v)) {
          return currentPetType === 'cats';
        } else {
          return currentPetType === 'dogs';
        }
      });
    } else {
      if (isCatSegmentation(entry[1])) {
        if (currentPetType === 'cats') {
          value = entry[1];
        }
      } else {
        if (currentPetType === 'dogs') {
          value = entry[1];
        }
      }
    }
    output[entry[0]] = value;
  });

  return output;
};

/**
 * Helper function to generate input from array of segmentationIds
 */
export const generateInputFromSegmentationIds = ({
  segmentationIds,
}: {
  segmentationIds: Array<SegmentationIds>;
}): SmartCollectionInput => {
  let apiInput: Partial<SmartCollectionInput> = {};
  for (const value of segmentationIds) {
    const processed = processSegmentation(value);
    apiInput = mergeDynamicInput({
      target: apiInput,
      source: processed,
    });
  }
  return apiInput as SmartCollectionInput;
};

export const mergeDynamicInput = ({
  source,
  target,
}: {
  source: Partial<SmartCollectionInput>;
  target: Partial<SmartCollectionInput>;
}): SmartCollectionInput => {
  // Merge arrays rather than overwrite
  return {
    ...source,
    ...Object.keys(target).reduce((acc, key) => {
      const existingValue = source[key as keyof SmartCollectionInput];
      const newValue = target[key as keyof typeof target];

      if (Array.isArray(existingValue) && Array.isArray(newValue)) {
        // If both are arrays, concatenate them
        acc[key] = Array.from(new Set([...existingValue, ...newValue]));
      } else if (newValue !== undefined) {
        // Otherwise, just assign the new value
        acc[key] = newValue;
      }

      return acc;
    }, {} as Partial<SmartCollectionInput>),
  } as SmartCollectionInput;
};

/**
 * Helper function to generate SmartCollectionInput from segmentation
 * @TODO : age mapping and allergy mapping
 */
export const generateInputFromCdpPath = ({
  path,
}: {
  path: string;
}): SmartCollectionInput | undefined => {
  const segmentation = getSegmentationFromUrl({
    url: path,
  });

  if (segmentation) {
    const { id } = segmentation;
    return processSegmentation(id) as SmartCollectionInput;
  }

  const config = getProductTypeFromCDPUrl({ url: path });

  if (config) {
    return {
      food_types: [config.productType],
    };
  }
};
